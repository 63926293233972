// src/App.js
import React, { createContext, useState, useRef, useEffect, useCallback } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes, Navigate, json } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Signup from "./components/Signup";
import UpdateSignal from "./components/UpdateSignal";
import Dashboard from "./components/Dashboard"; // Protected route
import Home from "./pages/Home"; // Protected route
import ManageContacts from "./components/ManageContacts";
import Login from "./pages/Login";
import './App.css';
import Signaling from "./components/Signaling";
import { Provider } from 'react-redux';
import { store } from './utils/store';
import Contacts from "./components/Contacts";
import { setReference, getReference, removeReference } from './utils/referenceStore'; // Adjust the path accordingly
import { db } from "./firebase";
import { doc, updateDoc, onSnapshot, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";

import { getDocRefByEmail, getIncomingConnectionRefByEmail } from "./utils/userUtils";

import MessageInput from './components/MessageInput';
import MessageDisplay from './components/MessageDisplay';
import VideoCall from "./components/VideoCall";
import Chats from "./components/Chats";
import RingerComponent from "./components/RingerComponent";
import DailingComponent from "./components/DailingComponent";
import ConnectingComponent from "./components/ConnectingComponent";
import ConnectingAudioComponent from "./components/ConnectingAudioComponent";
import AudioCall from "./components/AudioCall";
import RingerAudioComponent from "./components/RingerAudioComponent";
import DailingAudioComponent from "./components/DailingAudioComponent";
import ScreenShare from "./components/ScreenShare";
import RemoteView from "./components/RemoteView";
import FileTransfer from "./components/FileTransfer";
import ManualWebRTC from "./components/ManualWebRTC";
import QRScanner from "./components/QRScanner";
import ChatWindow from "./components/ChatWindow";
import ChatList from "./components/ChatList";
import Footer from "./components/Footer";
import OutboundCallComponent from "./components/OutboundCallComponent";
//import NotificationPermission from "./components/NotificationPermission";
//import NotificationComponent from "./components/NotificationComponent";


const DBWorkerLoad = new Worker(new URL('./components/indexedDB.js', import.meta.url));
const DBWorker = new Worker(new URL('./components/indexedDB.js', import.meta.url));

var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

DBWorkerLoad.postMessage({ dbName: 'users', action: "init"});

// Create the context
export const ConnectorContext = createContext();

const App = () => {

  //const [isServiceWorkerSupported, setIsServiceWorkerSupported] = useState(true);
  //const [notificationsSupported, setNotificationsSupported] = useState(true);


  useEffect(() => {
    /*if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function (registration) {
        console.log('Service Worker is ready and controlling the client.');
        // You can also handle more logic here, such as sending messages or setting up notifications
      }).catch(function(error) {
        console.error('Service Worker is not ready:', error);
      });
    }

    if (!('Notification' in window)) {
      setNotificationsSupported(false);
    }

    if (!('serviceWorker' in navigator)) {
      setIsServiceWorkerSupported(false);
    }*/
  }, []); // Runs only once, when the component mounts

  // Shared message state and state to control which component to display
  const [peers, setPeers] = useState({});
  const [chatMessages, setChatMessages] = useState({});
  const [currentCall2, setCurrentCall] = useState({});
  const [input, setInput] = useState({});
  const [addVideo, setAddVideo] = useState(false);
  const [addVideoRequest, setAddVideoRequest] = useState(false);
  //const [timestamp, setTimestamp] = useState({});
  //const [remoteTimestamp, setRemoteTimestamp] = useState({});
  const [localStream, setLocalStream ] = useState(null);
  const [remoteStream, setRemoteStream ] = useState(null);
  const [sharedScreenStream, setSharedScreenStream ] = useState(null);
  const [user, setUser] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [calls, setCalls] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [requester, setRequester] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);  // Control which component to show
  const [isRinging, setIsRinging] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [connectionOpen, setConnectionOpen] = useState(false);
  const [addScreenShare, setAddScreenShare] = useState(false);
  const [addScreenShareRequest, setAddScreenShareRequest] = useState(false);
  const [shareRequester, setShareRequester] = useState(null);
  const [activeVideoCall, setActiveVideoCall] = useState(false);
  const [activeAudioCall, setActiveAudioCall] = useState(false);
  const [localOffer, setLocalOffer] = useState('');
  const [localAnswer, setLocalAnswer] = useState('');
  const [localIceCandidates, setLocalIceCandidates] = useState([]);
  const [active, setActive] = useState('chats');


  //let polite = {};

  // Generate a timestamp for when the peer opens the app
  //let timestamp = {};

  // Assume you have a shared communication channel to exchange timestamps
  //let remoteTimestamp = {};

  //let makingOffer = {}

  //let localStreamRef = null;
  //let fullScreenVideoRef = null;
  // Video element refs
  const currentUser = useRef(null);
  const fullScreenVideoRef = useRef(null);
  const floatingVideoRef = useRef(null);
  let polite = useRef({});
  let timestamp = useRef({});
  let remoteTimestamp = useRef({});
  let currentCall = useRef({});
  let makingOffer = useRef({});
  let currentSelectedContact = useRef(null);
  let activeComponent = useRef(null);
  let currentPeers = useRef({});
  let currentChatMessages = useRef({});
  let audio = useRef(null);
  let localAudioRef = useRef(null);
  let remoteAudioRef = useRef(null);
  let screenStreamRef = useRef(null);
  let screenVideoRef = useRef(null);
  let sharedScreenLabelRef = useRef(null);
  let configuration = useRef([]);

  // After receiving the remote timestamp, decide politeness
  function compareTimestamps(id) {
    console.log(timestamp.current[id], remoteTimestamp.current[id])
      if (timestamp.current[id] > remoteTimestamp.current[id]) {
          //setPolite({[id] : true});
          polite.current[id] = true;
          console.log(id, "This is the polite peer.");
      } else {
        //setPolite({[id] : false});
        polite.current[id] = false;
          console.log(id, "This is the impolite peer.");
      }
  }

  // Expose the store globally for console access (for development purposes)
  window.DBWorkerLoad = DBWorkerLoad;
  window.DBWorker = DBWorker;

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(null);

  const renderPage = () => {
    switch (currentPage) {
      case 'contacts':
        if (user) {
          return <Contacts />;
        } else {
          return <Login />;
        }
      case 'update-signal':
        if (user) {
          return <UpdateSignal />;
        } else {
          return <Contacts />;
        }
      case 'signup':
        return <Signup />;
      case 'login':
        return <Login />;
      default:
        return <div>Loading...</div>;
    }
  }; 

  const pause = (duration) => {
    return new Promise(resolve => setTimeout(resolve, duration));
  };

  let unsubscribeSignal;

  const startListeningSignal = useCallback(async(user) => {
      // Set up a real-time listener for the signal data
      const docRef = await getIncomingConnectionRefByEmail(user.email);
      if (docRef) {
        unsubscribeSignal = onSnapshot(docRef, async (docSnapshot) => {
            if (docSnapshot.exists()) {
                let data = docSnapshot.data();
                if (!data.connections) {
                    data.connections = []
                }
                //console.log(data, user.email, peers, peers[user.email])
                data.connections.forEach(request => {
                    console.log(request, peers, currentPeers);
                    if (!remoteTimestamp.current[request.email]) {
                      remoteTimestamp.current[request.email] = request.timestamp;
                    }
                    if (!timestamp.current[request.email]) {
                      timestamp.current[request.email] = Date.now();
                    }
                    console.log(remoteTimestamp.current)
                    compareTimestamps(request.email)
                    console.log(polite, currentPeers.current[request.email])
                    if (undefined === currentPeers.current[request.email] || undefined === currentPeers.current[request.email].connectionState) {
                        console.log("new Connection")
                        setRequester(request.email)
                        //fetchUserDocRef(request.email, user.email);
                        startListeningUser(request.email, user);
                    } else {
                      console.log(polite, currentPeers.current[request.email].connectionState)
                    }
                    updateDoc(docRef, {
                        connections: arrayRemove(request)
                    })
                });
            }
        });
      }
    }, [peers, currentPeers]);

  function stopListeningSignal() {
    console.log(unsubscribeSignal)
      // Unsubscribe from the listener if it's active
      if (unsubscribeSignal) {
        unsubscribeSignal();
        console.log("Listener has been unsubscribed");
      }
  }

  let unsubscribeUser = {};

  const startListeningUser = useCallback(async(email, activeUser) => {
    const docRef = await getDocRefByEmail(email);
    const activeDocRef = await getDocRefByEmail(activeUser.email);
      //console.log(email)
      if (docRef) {
        // Set up a real-time listener for the signal data
        unsubscribeUser[email] = onSnapshot(docRef, async (docSnapshot) => {
          if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              //console.log(data.userProfile.email, email)
              if (data.userProfile.email === email && data.signal[activeUser.email]) {
                  //console.log(data.signal[activeUser.email])
                  const signal = JSON.parse(data.signal[activeUser.email])
                  //console.log(signal.type)
                  if (signal.type === 'offer') {
                      try {
                          delete data.signal[activeUser.email];
                          updateDoc(docRef, { signal: data.signal })
                          handleOffer(email, signal, activeUser)
                      } catch (error) {
                          console.error('Error handling offer:', error);
                      }
                      
                  } else if (signal.type === 'answer') {
                      try {
                          delete data.signal[activeUser.email];
                          updateDoc(docRef, { signal: data.signal })
                          console.log(signal, user, email, peers, currentPeers)
                          handleAnswer(email, signal, activeUser)
                          stopListeningUser(email)
                        } catch (error) {
                          console.error('Error setting remote description:', error);
                      }
                  } else if (signal.type === 'candidates') {
                      delete data.signal[activeUser.email];
                      updateDoc(docRef, { signal: data.signal })
                      console.log(currentPeers, signal)
                      const remoteICECandidates = signal.candidates;

                      for (const candidate of remoteICECandidates) {
                        await handleCandidate(signal.email, candidate);
                      }
                      stopListeningUser(signal.email)
                  }
              }
          }
      });
    }
  }, [peers]);

  function stopListeningUser(id) {
      // Unsubscribe from the listener if it's active
      if (unsubscribeUser[id]) {
        unsubscribeUser[id]();
        console.log("Listener has been unsubscribed");
      }
  }

  let unsubscribeAuth = null;

  function startListeningAuth() {
      
      unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
        const fetchContacts = async (userId) => {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
      
          if (docSnap.exists()) {
              const data = docSnap.data();
              setContacts(data.contacts || []);
              //console.log(data.contacts)
          }
        };
        
        if (user) {
          //await fetchContacts(user.uid);
          //console.log(user)
          setUser(user);
          currentUser.current = user
          DBWorkerLoad.postMessage({ storeName: 'lastUser', action: "save", value: [{title: "lastUser", email: user.email}]});
          DBWorkerLoad.postMessage({ storeName: 'users', action: "save", value: [JSON.parse(JSON.stringify(user))]});
          setLoading(false);
          DBWorker.postMessage({ dbName: user.email, action: "init"});
          await startListeningSignal(user)
          //await fetchIncomingConnectionDocRef(user);
          //activeComponent.current = 'contacts'
          //setCurrentComponent('contacts')
        } else {
          setLoading(false);
        }
      });
  }

  function stopListeningAuth() {
      // Unsubscribe from the listener if it's active
      if (unsubscribeAuth) {
        unsubscribeAuth();
        console.log("Listener has been unsubscribed");
      }
  }

  let storedUsers = []

  DBWorkerLoad.onmessage = async function (msg) {
    var msgData = msg.data;
    //console.log(msgData)
    switch (msgData.name) {
			case "users":
				{
					//console.log(msgData.value)
          storedUsers = msgData.value
          DBWorkerLoad.postMessage({ storeName: 'lastUser', action: "readAll"});
				}
				break;
			case "lastUser":
				{
          if (msgData.value.length !== 0) {
            //console.log(msgData.value)
            const lastUserEmail = msgData.value[0].email
            const lastUser = storedUsers.filter(elem=>elem.email === lastUserEmail)
            //console.log(lastUserEmail, lastUser, storedUsers)
            if (lastUser.length !== 0) {
              setUser(lastUser[0]);
              currentUser.current = lastUser[0]
              //setLoading(false);
              DBWorker.postMessage({ dbName: lastUserEmail, action: "init"});
              await startListeningSignal(lastUser[0]);
            } else {
              startListeningAuth()
            }
          } else {
            startListeningAuth()
          }
				}
				break;
		}
  }

  function groupByFromOrTo(data, activeUser) {
    return data.reduce((acc, item) => {
        let groupKey = '';

        if (item.from === activeUser) {
            groupKey = item.to; // If "from" is activeUser, group under "to"
        } else if (item.to === activeUser) {
            groupKey = item.from; // If "to" is activeUser, group under "from"
        } else {
            groupKey = item.from; // If neither "from" nor "to" is activeUser, group by "from"
        }

        // Initialize the array if it doesn't exist
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }

        // Push the item into the appropriate group
        acc[groupKey].push(item);

        return acc;
    }, {});
  }

  DBWorker.onmessage = async function (msg) {
    var msgData = msg.data;
    console.log(msgData)
    switch (msgData.name) {
			case "ready":
				{
					//console.log(msgData.value)
          storedUsers = msgData.value
          DBWorker.postMessage({ storeName: 'contacts', action: "readAll"});
				}
				break;
			case "contacts":
				{
					console.log(msgData.value, user)
          setContacts(msgData.value || []);
          activeComponent.current = 'contacts'
          setCurrentComponent('contacts')
          DBWorker.postMessage({ storeName: 'chats', action: "readAll"});
          DBWorker.postMessage({ storeName: 'calls', action: "readAll"});
				}
				break;
			case "chats":
				{
					console.log(msgData.value, contacts)
					console.log(groupByFromOrTo(msgData.value, currentUser.current.email))
					setChatMessages(groupByFromOrTo(msgData.value, currentUser.current.email))
          currentChatMessages.current = groupByFromOrTo(msgData.value, currentUser.current.email)
          setLoading(false);
          
          Object.entries(currentChatMessages.current).forEach(([key, value]) => {
            if (value.filter(elem=>elem.waiting).length !== 0) {
              const currentContact = contacts.filter(elem=>elem.email === key)[0]
              setSelectedContact(currentContact);
              currentSelectedContact.current = currentContact;
              
              if (!currentPeers.current[currentContact.email] && !remoteTimestamp.current[currentContact.email]) {
                  if (!timestamp.current[currentContact.email]) {
                      timestamp.current[currentContact.email] = Date.now();
                  }
                  polite.current[currentContact.email] = false
                  makingOffer.current[currentContact.email] = false
                  //handleAddConnection(contact)
                  //console.log(contact, selectedContact)
                  updateIncomingConnections(user.email, currentContact.email, {type: "chats", timestamp: timestamp.current[currentContact.email]})
                  startListeningUser(currentContact.email, user);
                  //const peerConnection = createPeerConnection(contact.email);
                  startCall(currentContact);
              }
            }
          });
					//setChatMessages(msgData.value)
				}
				break;
			case "calls":
				{
					console.log(msgData.value)
          const allCalls = msgData.value.sort((a, b) => b.time.localeCompare(a.time))
					setCalls(allCalls || []);
				}
				break;
			case "done":
				{
					// No code here
				}
				break;
		}
  }

  

  useEffect(() => {

    //DBWorker.postMessage({ storeName: 'lastUser', action: "readAll"});

    //startListeningAuth()
    /*if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log("Notification permission granted.");
        }
      });
    }
    if (navigator.serviceWorker) {
      console.log("event")
      navigator.serviceWorker.addEventListener('message', function(event) {
        console.log(event)
        if (event.data && event.data.type === 'REPLY_CLICKED') {
          console.log('Reply was clicked! Message ID:', event.data.data.sender);
          setSelectedContact({email: event.data.data.sender})
          currentSelectedContact.current = {email: event.data.data.sender}
          activeComponent.current = 'chats'
          setCurrentComponent("chats")
          // You can set a state variable or trigger an action here
          // setReplyClicked(true);  // Example
          console.log("Reply Clicked")
        }
      });
    }*/
  }, []);

  // Function to trigger a notification
  const triggerNotification = (data) => {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.showNotification(data.title || 'Notification', {
        body: data.body,
        icon: '/logo192.png',
        actions: data.actions, // Action buttons (e.g., "Answer", "Decline")
        data: data // Pass along the data for handling in service worker
      });
    });
  };

  /*

  const showNotification = (title, body) => {
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, {
        body,
        icon: 'path-to-call-icon.png',
        actions: [
          { action: 'answer', title: 'Answer' },
          { action: 'decline', title: 'Decline' },
        ],
        tag: 'incoming-call',
      });
  
      notification.onclick = () => {
        window.focus();
        // Redirect to call handling screen
      };
  
      notification.onclose = () => {
        console.log('Notification closed');
      };
  
      notification.addEventListener('action', (event) => {
        if (event.action === 'answer') {
          console.log('Answer the call');
          // Handle answer action (e.g., redirect to answer screen)
        } else if (event.action === 'decline') {
          console.log('Decline the call');
          // Handle decline action
        }
      });
    }
  };
  */

  let defaultConfiguration = useRef({
    iceServers: [
      {'urls': 'stun:stun.l.google.com:19302'},
      {'urls': 'stun:stun.services.mozilla.com'},
      {'urls': 'stun:stun1.l.google.com:19302'},
      {'urls': 'stun:stun2.l.google.com:19302'},
      {'urls': 'stun:stun3.l.google.com:19302'},
      {'urls': 'stun:stun4.l.google.com:19302'},
    ]
  });

  configuration.current = defaultConfiguration.current
  
  /*
  const configuration = {
    iceServers: [
      {
        urls: "stun:stun.relay.metered.ca:80",
      },
      {
        urls: "turn:global.relay.metered.ca:80",
        username: "3f00e3716c3deea921ce6ae9",
        credential: "nlfyUAiQ9MaSYNvh",
      },
      {
        urls: "turn:global.relay.metered.ca:80?transport=tcp",
        username: "3f00e3716c3deea921ce6ae9",
        credential: "nlfyUAiQ9MaSYNvh",
      },
      {
        urls: "turn:global.relay.metered.ca:443",
        username: "3f00e3716c3deea921ce6ae9",
        credential: "nlfyUAiQ9MaSYNvh",
      },
      {
        urls: "turns:global.relay.metered.ca:443?transport=tcp",
        username: "3f00e3716c3deea921ce6ae9",
        credential: "nlfyUAiQ9MaSYNvh",
      },
    ]
  };*/
  
  /*
  const configuration = {
    iceServers: [
      {'urls': 'stun:stun.services.mozilla.com'},
      {'urls': 'stun:stun.l.google.com:19302'},
      {'urls': 'stun:stun.services.mozilla.com'},
      {'urls': 'stun:stun1.l.google.com:19302'},
      {'urls': 'stun:stun1.l.google.com:19302'},
      {'urls': 'stun:stun2.l.google.com:19302'},
      {'urls': 'stun:stun3.l.google.com:19302'},
      {'urls': 'stun:stun4.l.google.com:19302'},
      {'urls': 'stun:stun01.sipphone.com'},
      {'urls': 'stun:stun.ekiga.net'},
      {'urls': 'stun:stun.fwdnet.net'},
      {'urls': 'stun:stun.ideasip.com'},
      {'urls': 'stun:stun.iptel.org'},
      {'urls': 'stun:stun.rixtelecom.se'},
      {'urls': 'stun:stun.schlund.de'},
      { urls: 'stun:74.125.140.127:19302' },
    ]
  };*/

  const createPeerConnection = useCallback((id) => {
    let peerConnection = null
    let manual = false
    if (undefined === configuration.current.iceServers) {
      peerConnection = new RTCPeerConnection();
      manual = true
      console.log(configuration.current, manual)
    } else {
      peerConnection = new RTCPeerConnection(configuration.current);
    }

    // Create Data Channels for signal, chat and file transfers
    const signalDataChannel = peerConnection.createDataChannel("signal");
    const chatDataChannel = peerConnection.createDataChannel("chat");
    const messagesDataChannel = peerConnection.createDataChannel("messages");
    const fileDataChannel = peerConnection.createDataChannel("file");
    //console.log(id, peerConnection)
  
    // Handle the data channel
    signalDataChannel.onopen = () => {
      console.log("Signal channel is open!", activeComponent.current);
      setConnectionOpen(true)
      if (activeComponent.current === 'connecting') {
        //console.log(contact, selectedContact)
        activeComponent.current = 'dialing'
        setCurrentComponent('dialing')
        signalDataChannel.send(JSON.stringify({type: "videoCall", contact: user.email}));
      } else if (activeComponent.current === 'connectingAudio') {
        //console.log(contact, selectedContact)
        activeComponent.current = 'dialingAudio'
        setCurrentComponent('dialingAudio')
        signalDataChannel.send(JSON.stringify({type: "audioCall", contact: user.email}));
      } else if (activeComponent.current === 'shareScreen') {
        //console.log(currentSelectedContact.current)
        activeComponent.current = 'contacts'
        signalDataChannel.send(JSON.stringify({type: "screenShareRequest", contact: user.email}));
        //startScreenShare(currentSelectedContact.current.email)
      } else if (activeComponent.current === 'manual') {
        //console.log(currentSelectedContact.current)
        activeComponent.current = 'contacts'
        setCurrentComponent('contacts')
      }
      // Optionally, you can send a ready message when the channel is open
      //dataChannel.send("Data channel ready for SDP exchange.");
    };

    signalDataChannel.onmessage = handleSignalMessage(id);
    
    chatDataChannel.onmessage = handleChatMessage(id);
    messagesDataChannel.binaryType = 'arraybuffer'; // Set binary type for file data channel
    messagesDataChannel.onmessage = handleMessageTransfer(id);
    fileDataChannel.binaryType = 'arraybuffer'; // Set binary type for file data channel
    fileDataChannel.onmessage = handleFileTransfer(id);

    var localICECandidates = []

    peerConnection.onicecandidate = event => {
        if (event.candidate) {
            localICECandidates.push(JSON.stringify(event.candidate))
        }
    };

    peerConnection.ontrack = event => {
        console.log(`Received remote stream from peer ${id}`);
        if (peers) {
          var updatedPeers = { ...currentPeers.current };
          if (event.streams[0].id === sharedScreenLabelRef.current) {
            updatedPeers[id].screenStream = event.streams[0];
            //fullScreenVideoRef.current.srcObject = event.streams[0];
            setSharedScreenStream(event.streams[0]);
            //const screenVideoElement = document.getElementById("remoteScreen");
            //screenVideoElement.srcObject = remoteStream;
            sharedScreenLabelRef.current = null
            //activeComponent.current = 'remote'
            setCurrentComponent('remote')
          } else {
            updatedPeers[id].remoteStream = event.streams[0];
            //fullScreenVideoRef.current.srcObject = event.streams[0];
            setRemoteStream(event.streams[0]);
          }
          setPeers(updatedPeers);
          currentPeers.current = updatedPeers;
        };
    };

    // Handle negotiationneeded (when you need to create an offer)
    peerConnection.onnegotiationneeded = async () => {
      try {
          console.log(currentPeers.current[id], user, configuration.current)
          if (currentPeers.current[id].signalDataChannel.readyState === 'open') {
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            console.log("Your offer (copy this and send it to the other peer):\n", JSON.stringify(peerConnection.localDescription));
            signalDataChannel.send(JSON.stringify(offer));
          } else if (manual) {
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            //setLocalOffer(JSON.stringify(offer))
            //console.log("Your offer (copy this and send it to the other peer):\n", JSON.stringify(peerConnection.localDescription));
          } else {
            makingOffer.current[id] = true;
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            console.log(currentUser.current)
            updateSignalField(JSON.stringify(peerConnection.localDescription), currentUser.current.uid, id)
          }
      } catch (error) {
          console.error("Error during negotiation:", error);
      } finally {
          makingOffer.current[id] = false;
      }
    };

    peerConnection.ondatachannel = event => {
      console.log(event.channel)
      if (event.channel.label === "signal") {
          event.channel.onmessage = handleSignalMessage(id);
      } else if (event.channel.label === "chat") {
          event.channel.onmessage = handleChatMessage(id);
          Object.entries(currentChatMessages.current).forEach(([key, value]) => {
            if (key === id) {
              var i = 0
              value.forEach(msg=>{
                if (msg.waiting) {
                  console.log(id, msg.message);
                  const message = JSON.stringify(msg.message)
                  event.channel.send(JSON.stringify({ type: 'chat', message }));
                  DBWorker.postMessage({ storeName: 'chats', action: "save", value: [{time: msg.time, message: JSON.parse(message), to: msg.to, from: msg.from}]});
                  var updatedChatMessages = currentChatMessages.current
                  updatedChatMessages[id][i] = {time: msg.time, message: JSON.parse(message), to: msg.to, from: msg.from}
                  
                  console.log(updatedChatMessages)

                  //setReference(chatMessagesKey, updatedChatMessages);
                  currentChatMessages.current = updatedChatMessages
                  setChatMessages(updatedChatMessages);
                }
                i++
              })
            }
          });
      } else if (event.channel.label === "message") {
          event.channel.binaryType = 'arraybuffer'; // Set binary type for file data channel
          event.channel.onmessage = handleMessageTransfer(id);
      } else if (event.channel.label === "file") {
          event.channel.binaryType = 'arraybuffer'; // Set binary type for file data channel
          event.channel.onmessage = handleFileTransfer(id);
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      console.log("ICE Connection State:", peerConnection.iceConnectionState);
    };

    peerConnection.onsignalingstatechange = () => {
      console.log("Signaling State:", peerConnection.signalingState);
    };

    peerConnection.onicecandidateerror = (event) => {
      console.error("ICE candidate error:", event.errorCode, event.errorText);
    };
  
    
    peerConnection.onicegatheringstatechange = async() => {
      if (peerConnection.iceGatheringState === 'complete') {

          if (peers) {
              const updatedPeers = {
                  ...peers,
                  [id]: {
                      connection: peerConnection,
                      chatDataChannel,
                      messagesDataChannel,
                      fileDataChannel,
                      signalDataChannel,
                      localStream: null,
                      remoteStream: null,
                      screenStream: null,
                      localICECandidates: localICECandidates,
                      remoteICECandidates: [],
                  }
              }
              console.log("Updating Ice candidates")
              setPeers(updatedPeers);
              currentPeers.current = updatedPeers;
          }
      }
    };

    if (peers) {
        const updatedPeers = {
            ...peers,
            [id]: {
                connection: peerConnection,
                chatDataChannel,
                messagesDataChannel,
                fileDataChannel,
                signalDataChannel,
                localStream: null,
                remoteStream: null,
                screenStream: null,
                localICECandidates: localICECandidates,
                remoteICECandidates: [],
            }
        }
        setPeers(updatedPeers);
        currentPeers.current = updatedPeers;
    } else {
        setPeers(
            {
                [id]: {
                    connection: peerConnection,
                    chatDataChannel,
                    messagesDataChannel,
                    fileDataChannel,
                    localStream: null,
                    remoteStream: null,
                    screenStream: null,
                    localICECandidates: localICECandidates,
                    remoteICECandidates: [],
                }
            }
        );
        currentPeers.current = {
                [id]: {
                    connection: peerConnection,
                    chatDataChannel,
                    messagesDataChannel,
                    fileDataChannel,
                    localStream: null,
                    remoteStream: null,
                    screenStream: null,
                    localICECandidates: localICECandidates,
                    remoteICECandidates: [],
                }
            };
    }

    return peerConnection;
  }, [peers, user, currentPeers, activeComponent, currentUser]);

  // First, capture both audio and video from the user
  async function getMediaStream(type) {
    try {
        if (type) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          setActiveAudioCall(true)
          return stream;
        }
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setActiveVideoCall(true)
        return stream;
    } catch (error) {
        console.error("Error accessing media devices:", error);
    }
  }

  // Function to add media (audio or video) and renegotiate using the data channel
  const addMediaStream = useCallback(async(id, type) => {
    try {
        // Start renegotiation after adding tracks
        const peerConnection = currentPeers.current[id].connection
        
        const stream = await getMediaStream(type);
      
        // Add audio track first (ensure both peers add in the same order)
        stream.getAudioTracks().forEach(track => peerConnection.addTrack(track, stream));
        
        //if (!type) {
          // Then add video track (in the same order for both peers)
        stream.getVideoTracks().forEach(track => peerConnection.addTrack(track, stream));
        //}

        currentPeers.current[id].localStream = stream
        setLocalStream(stream)
        //floatingVideoRef.current.srcObject = stream;

        // Renegotiate after adding tracks
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        // Send the offer over the data channel
        currentPeers.current[id].signalDataChannel.send(JSON.stringify(offer));
        console.log("Offer sent over data channel.");
    } catch (error) {
        console.error("Error adding media stream or renegotiating:", error);
    }
  }, [peers, user, currentPeers]);

  // Start screen sharing
  const startScreenShare = async (id) => {
    try {

      const peerConnection = currentPeers.current[id].connection

      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: false
      });

      screenStreamRef.current = screenStream;
      //screenVideoRef.current.srcObject = screenStream;
      setIsSharing(true);

      // Pass the screen stream to the parent component or WebRTC peer
      //onShareStream(screenStream);
      // Add audio track first (ensure both peers add in the same order)
      screenStream.getAudioTracks().forEach(track => peerConnection.addTrack(track, screenStream));
        
      // Then add video track (in the same order for both peers)
      screenStream.getVideoTracks().forEach(track => peerConnection.addTrack(track, screenStream));
      
      
      currentPeers.current[id].screenStream = screenStream

      setSharedScreenStream(screenStream);
      console.log(screenStream);

      // Renegotiate after adding tracks
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      // Send the offer over the data channel
      currentPeers.current[id].signalDataChannel.send(JSON.stringify({ ...offer, label: screenStream.id }));
      //currentPeers.current[id].signalDataChannel.send(JSON.stringify({type: "remote", label: screenStream.id}));
      console.log("Offer sent over data channel.");

      // Handle when the user stops sharing
      screenStream.getVideoTracks()[0].addEventListener("ended", () => {
        stopScreenShare(id);
      });
      //activeComponent.current = 'screenShare'
      setCurrentComponent('screenShare')
      
    } catch (error) {
      console.error("Error sharing screen:", error);
    }
  };

  // Stop screen sharing
  const stopScreenShare = (id) => {
    if (screenStreamRef.current) {
      const tracks = screenStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      if (screenVideoRef.current) screenVideoRef.current.srcObject = null;
      setIsSharing(false);
      //onShareStream(null); // Inform parent component that sharing has stopped
      setSharedScreenStream(null); // Inform parent component that sharing has stopped
      setCurrentComponent(activeComponent.current);
      currentPeers.current[id].signalDataChannel.send(JSON.stringify({type: "endScreenShare"}));
      
    }
  };

/*
    
*/

  // Handle incoming offer
  const handleOffer = useCallback(async(id, offer, user, manual) => {
    let peerConnection = null
    const offerDesc = new RTCSessionDescription(offer);

    if (currentPeers.current[id]) {
      await handleRollback()
      peerConnection = currentPeers.current[id].connection
    } else {
      //const peerConnection = new RTCPeerConnection(configuration);
      peerConnection = createPeerConnection(id)
    }
       
    
    //console.log(offerDesc)

    console.log(user, currentPeers)


    //const peerConnection = peers[id].connection;

    const isStable = peerConnection.signalingState === "stable";
    const isSettingRemote = !isStable || makingOffer.current[id];

    if (!polite.current[id] && isSettingRemote) {
        console.log("Impolite peer detected offer collision, rejecting offer.");
        return; // Impolite peer rejects the offer if already making one.
    }

    try {
        console.log("Setting", peerConnection.signalingState)
        await peerConnection.setRemoteDescription(offerDesc);

        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
        console.log(peerConnection)
        if (currentPeers.current[id] && currentPeers.current[id].signalDataChannel.readyState === 'open') {
          console.log("Your answer (copy this and send it to the other peer):\n", JSON.stringify(peerConnection.localDescription));
          currentPeers.current[id].signalDataChannel.send(JSON.stringify(peerConnection.localDescription));
        } else if (manual) {
          //console.log("Your answer (copy this and send it to the other peer):\n", JSON.stringify(peerConnection.localDescription));
          //setLocalAnswer(JSON.stringify(answer))
          //console.log("Your offer (copy this and send it to the other peer):\n", JSON.stringify(peerConnection.localDescription));
        } else {
          updateSignalField(JSON.stringify(peerConnection.localDescription), user.uid, id)
        }
        
    } catch (error) {
        console.error("Error handling offer:", error);
    }
  }, [peers, currentPeers]);

  // Handle incoming answer
  const handleAnswer = useCallback(async(id, answer, activeUser, manual) => {
    //const answerDesc = new RTCSessionDescription(answer);
    console.log(peers, currentPeers.current)
    console.log(id, answer, activeUser, manual)
    const peerConnection = currentPeers.current[id].connection;
    console.log(peerConnection)
    console.log(currentPeers.current[id], polite.current[id])
    try {
        if (peerConnection.signalingState === "have-local-offer" && !polite.current[id]) {
          const answerDesc = new RTCSessionDescription(answer);
          await peerConnection.setRemoteDescription(answerDesc);
          console.log("Remote answer successfully set.");
          if (currentPeers.current[id] && currentPeers.current[id].signalDataChannel.readyState === 'open') {
            console.log("Your candidates (copy this and send it to the other peer):\n", JSON.stringify(peers[id].localICECandidates));
            currentPeers.current[id].signalDataChannel.send(JSON.stringify(peers[id].localICECandidates));
          } else if (manual) {
            //console.log("Your candidates (copy this and send it to the other peer):\n", JSON.stringify(peers[id].localICECandidates));
            setLocalIceCandidates(peers[id].localICECandidates);
            //currentPeers.current[id].signalDataChannel.send(JSON.stringify(peers[id].localICECandidates));
          } else {
            const activeDocRef = await getDocRefByEmail(activeUser.email);          
            console.log(JSON.stringify({ email: activeUser.email, type: "candidates", candidates: currentPeers.current[id].localICECandidates }), activeDocRef.id, id);
            updateSignalField(JSON.stringify({ email: activeUser.email, type: "candidates", candidates: currentPeers.current[id].localICECandidates }), activeDocRef.id, id);
        
          }
        } else {
          console.warn("Cannot set answer, signaling state is:", peerConnection.signalingState);
          /*if (polite.current[id]) {
            console.log(selectedContact)
            await startCall(selectedContact);
          } else {
            await handleRollback(id)
          }*/
        }
        
        
    } catch (error) {
        console.error("Error handling answer:", error);
    }
  }, [peers, currentPeers, selectedContact, polite]);

  // Handle rollback in case of collision
  const handleRollback = useCallback(async(id) => {
    if (polite.current[id]) {
        console.log("Polite peer detected offer collision, rolling back.");
        const peerConnection = currentPeers.current[id].connection;
        try {
            await peerConnection.setLocalDescription({ type: "rollback" });
        } catch (error) {
            console.error("Error during rollback:", error);
        }
    }
  }, [peers, currentPeers]);

  // Handle incoming ICE candidates
  const handleCandidate = useCallback(async(id, candidate) => {
    const peerConnection = currentPeers.current[id].connection;
    try {
        await peerConnection.addIceCandidate(new RTCIceCandidate(JSON.parse(candidate)));
    } catch (error) {
        if (!polite.current[id] && peerConnection.signalingState === "have-local-offer") {
            console.log("Impolite peer detected offer collision, ignoring candidate.");
            handleRollback(id)
            return;
        }
        console.error("Error adding ICE candidate:", error);
    }
  }, [peers, currentPeers]);

  const handleSignalMessage = (id) => async (event) => {
      
    //console.log("Message received on data channel:", event.data, id);

    // Check if the message is an SDP offer or answer
    const message = JSON.parse(event.data);

    console.log(message)

    if (message.type === "endCall") {
        endCall(message.contact)        
    } else if (message.type === "screenShareRequest") {
      setShareRequester(message.contact)
      setAddScreenShare(true)
      //setCurrentComponent(activeComponent.current);
    } else if (message.type === "cancelShareScreen") {
      setAddScreenShare(false);
    } else if (message.type === "declineShareScreen") {
      setAddScreenShareRequest(false);
    } else if (message.type === "acceptedShareScreen") {
      setAddScreenShareRequest(false);
      startScreenShare(currentSelectedContact.current.email)
    } else if (message.type === "endScreenShare") {
      setCurrentComponent(activeComponent.current);
    } else if (message.type === "cancelCall") {
      currentCall.current.call.handled = 'Missed'
      console.log(currentCall)
      DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
      handleDecline(message.contact)
        activeComponent.current = 'contacts'
    } else if (message.type === "answered") {
      
        setRequester(message.contact)
        activeComponent.current = 'calling'
        setCurrentComponent('calling')
    } else if (message.type === "declined") {
      currentCall.current.call.handled = 'Declined'
      console.log(currentCall)
      DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
        setSelectedContact(null)
        activeComponent.current = 'contacts'
        setCurrentComponent('contacts')
    } else if (message.type === "videoCall") {
      const currentTime = new Date().toISOString()
      currentCall.current = {time: `${currentTime} - ${message.contact}`, call: {type: "video"}, to: currentUser.current.email, from: message.contact }
      DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
      setCalls([currentCall.current].concat(calls))
      setRequester(message.contact)
        activeComponent.current = 'calling'
        setCurrentComponent('calling')
    } else if (message.type === "remote") {
        sharedScreenLabelRef.current = message.label
        //setRequester(message.contact)
        //activeComponent.current = 'remote'
        //setCurrentComponent('remote')
    } else if (message.type === "addVideo") {
        setAddVideo(true)
    } else if (message.type === "cancelAddVideo") {
        setAddVideo(false)
    } else if (message.type === "declineAddVideo") {
        setAddVideoRequest(false)
    } else if (message.type === "acceptedAddVideo") {
        setAddVideoRequest(false)
    } else if (message.type === "audioCall") {
      const currentTime = new Date().toISOString()
        currentCall.current = {time: `${currentTime} - ${message.contact}`, call: {type: "audio"}, to: currentUser.current.email, from: message.contact }
        DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
        setCalls([currentCall.current].concat(calls))
        setRequester(message.contact)
        activeComponent.current = 'callingAudio'
        setCurrentComponent('callingAudio')
    } else if (message.type === "offer") {
      if (message.label) {
        sharedScreenLabelRef.current = message.label
        await handleShareScreenOffer(id, message);
      } else if (activeComponent.current === "dialing") {
        // Handle the incoming offer and send the answer
        currentCall.current.call.handled = 'Answered'
        console.log(currentCall)
        DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
        
        await handleSignalOffer(id, message);
        activeComponent.current = 'videoCall'
        setCurrentComponent('videoCall')
      } else if (activeComponent.current === "dialingAudio") {
        // Handle the incoming offer and send the answer
        currentCall.current.call.handled = 'Answered'
        console.log(currentCall)
        DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
        
        await handleSignalOffer(id, message, "audio");
        activeComponent.current = 'audioCall'
        setCurrentComponent('audioCall')
      } else if (activeComponent.current === "audioCall") {
        // Handle the incoming offer and send the answer
        await handleSignalOffer(id, message);
        activeComponent.current = 'videoCall'
        setCurrentComponent('videoCall')
      } else {
        
        // Handle the incoming offer and send the answer
        await handleShareScreenOffer(id, message);
        //activeComponent.current = 'videoCall'
        //setCurrentComponent('videoCall')
        
      } 
    } else if (message.type === "answer") {
        // Handle the incoming answer
        await handleSignalAnswer(id, message);
    } else if (message.candidate) {
        // Handle ICE candidates sent over the data channel
        await handleCandidate(id, message.candidate);
    }
  };

  const handleDecline = useCallback((id) => {
    if (audio.current) audio.current.pause();   // Stop ringtone
    setIsRinging(false);
    setCurrentComponent('contacts')
    console.log(currentPeers, user)
    currentPeers.current[id].signalDataChannel.send(JSON.stringify({type: "declined"}));
    setRequester(null)
    setSelectedContact(null)
  }, [user]);

  // Handle an incoming offer (received over the data channel)
  async function handleSignalOffer(id, offer, type) {
    try {
        const peerConnection = currentPeers.current[id].connection
        
        // Get the user's media (audio and video) in the same order as the offerer
        const stream = await getMediaStream(type);

        // Add tracks in the same order as the offerer (audio first, then video)
        stream.getAudioTracks().forEach(track => peerConnection.addTrack(track, stream));
        
        //if (!type) {
        stream.getVideoTracks().forEach(track => peerConnection.addTrack(track, stream));
        //}

        currentPeers.current[id].localStream = stream
        setLocalStream(stream)
        //floatingVideoRef.current.srcObject = stream;

        const offerDesc = new RTCSessionDescription(offer);
        await peerConnection.setRemoteDescription(offerDesc);

        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        // Send the answer back over the data channel
        currentPeers.current[id].signalDataChannel.send(JSON.stringify(peerConnection.localDescription));
        console.log("Answer sent over data channel.");
    } catch (error) {
        console.error("Error handling offer or creating answer:", error);
    }
  }

  // Handle an incoming offer (received over the data channel)
  async function handleShareScreenOffer(id, offer, type) {
    try {
        const peerConnection = currentPeers.current[id].connection
    
        const offerDesc = new RTCSessionDescription(offer);
        await peerConnection.setRemoteDescription(offerDesc);

        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        // Send the answer back over the data channel
        currentPeers.current[id].signalDataChannel.send(JSON.stringify(answer));
        console.log("Answer sent over data channel.");
    } catch (error) {
        console.error("Error handling offer or creating answer:", error);
    }
  }

  // Handle the incoming answer (received over the data channel)
  async function handleSignalAnswer(id, answer) {
    try {

        const peerConnection = currentPeers.current[id].connection
        console.log(peerConnection)
        const answerDesc = new RTCSessionDescription(answer);
        await peerConnection.setRemoteDescription(answerDesc);
        console.log("Answer received and set.");
    } catch (error) {
        console.error("Error handling answer:", error);
    }
  }

  // Handle ending the call
  const endCall = (activeCaller) => {
    // Stop local and remote streams

    if (currentPeers.current[activeCaller].localStream) currentPeers.current[activeCaller].localStream.getTracks().forEach(track => track.stop());
    if (currentPeers.current[activeCaller].remoteStream) currentPeers.current[activeCaller].remoteStream.getTracks().forEach(track => track.stop());
    
    // Clear video sources
    if (fullScreenVideoRef.current) fullScreenVideoRef.current.srcObject = null;
    if (floatingVideoRef.current) floatingVideoRef.current.srcObject = null;

    activeComponent.current = 'contacts'
    setCurrentComponent('contacts')
    setActiveAudioCall(false)
    setActiveVideoCall(false)
  };

  // Handle ending the call
  const endAudioCall = (activeCaller) => {
    // Stop local and remote streams

    currentPeers.current[activeCaller].localStream.getTracks().forEach(track => track.stop());
    currentPeers.current[activeCaller].remoteStream.getTracks().forEach(track => track.stop());
    
    if (localAudioRef.current) localAudioRef.current.srcObject = null;
    if (remoteAudioRef.current) remoteAudioRef.current.srcObject = null;

    activeComponent.current = 'contacts'
    setCurrentComponent('contacts')
    setActiveAudioCall(false)
    setActiveVideoCall(false)
  };

  const handleChatMessage = useCallback((id) => (event) => {
    const data = JSON.parse(event.data);
    console.log(data, id, chatMessages, currentUser)
    DBWorker.postMessage({ storeName: 'chats', action: "save", value: [{time: `${new Date().toISOString()} - ${id}`, message: JSON.parse(data.message), to: currentUser.current.email, from: id}]});
    
    const updatedChatMessages = {
        ...currentChatMessages.current,
        [id]: [...(currentChatMessages.current[id] || []), {time: `${new Date().toISOString()} - ${id}`, message: JSON.parse(data.message), to: currentUser.current.email, from: id}]
    }
    console.log(updatedChatMessages, chatMessages)
    console.log(currentSelectedContact, id, activeComponent.current)
    // Show notification for the new message
    if (activeComponent.current === 'chats' && id === currentSelectedContact.current.email) {

    } else {
      /*triggerNotification({
        title: 'New Message',
        body: `Message from ${id}: "${JSON.parse(data.message).blocks.map(elem=>elem.text).join('\n')}"`,
        type: 'message',
        sender: id,
        message: data.message,
        actions: [
          { action: 'reply', title: 'Reply' },
          { action: 'ignore', title: 'Ignore' }
        ]
      });*/
    }
    setChatMessages(updatedChatMessages);
    currentChatMessages.current = updatedChatMessages
  },[chatMessages, currentSelectedContact, currentChatMessages, activeComponent, currentUser]);

let fileName = '', chunks = [], fileSize = '', lastDownloadedBytes = 0, lastTime = null, downloadedBytes = 0;
// State for outgoing files
const [file, setFile] = useState(null);
const [progress, setProgress] = useState(0);
const [status, setStatus] = useState(null);
const [speed, setSpeed] = useState(0);

const [incomingFile, setIncomingFile] = useState(null);
const [incomingProgress, setIncomingProgress] = useState(0);
const [incomingStatus, setIncomingStatus] = useState("");
const [incomingSpeed, setIncomingSpeed] = useState(0);


const handleFileTransfer = (id) => (event) => {
    const data = event.data;

    if (data.byteLength) {
        // File chunk
        chunks.push(data)
        downloadedBytes += data.byteLength
        setIncomingProgress((downloadedBytes / fileSize) * 100)
        
        // Calculate instantaneous speed
        const now = Date.now();
        const timeElapsed = (now - lastTime) / 1000; // Time in seconds since last calculation
        if (timeElapsed >= 1) { // Update speed every second
          const bytesSinceLast = downloadedBytes - lastDownloadedBytes;
          const newSpeed = bytesSinceLast / timeElapsed; // Bytes per second
          
          setIncomingSpeed(newSpeed); // Update speed state
          
          // Update tracking variables for the next interval
          lastDownloadedBytes = downloadedBytes;
          lastTime = now;
        }
        
    } else if (JSON.parse(data).type === 'start') {
        setIncomingStatus("Receiving...");
        // Start of file transfer
        fileName = JSON.parse(data).fileName;
        fileSize = JSON.parse(data).fileSize;
        chunks = []
        lastDownloadedBytes = 0;
        downloadedBytes = 0
        lastTime = Date.now();
        setIncomingFile({ name: fileName, size: fileSize });
        
        if (currentComponent !== 'fileTransfer') {
          setCurrentComponent('fileTransfer')
        }
    } else if (JSON.parse(data).type === 'end') {
        setIncomingStatus("Incoming Transfer Complete");
        const blob = new Blob(chunks);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
};

let messageName = '', messageChunks = [], messageSize = ''

const handleMessageTransfer = (id) => (event) => {
    const data = event.data;

    if (data.byteLength) {
        // File chunk
        chunks.push(data)
    } else if (JSON.parse(data).type === 'start') {
        // Start of file transfer
        messageName = JSON.parse(data).fileName;
        messageChunks = []
        messageSize = JSON.parse(data).fileSize;
        console.log(messageSize)
    } else if (JSON.parse(data).type === 'end') {
        const blob = new Blob(messageChunks);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = messageName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
};

  const updateSignalField = async (value, userId, email) => {
    try {
        if (userId) {
            const docRef = doc(db, "users", userId);

            const updatedSignal = { [email]: value };

            // Update only the specified field in the signal object
            await updateDoc(docRef, {
                signal: updatedSignal,
            });

            //alert(`${field} updated successfully!`);
        }
    } catch (error) {
        console.error("Error updating signal:", error);
        alert("Update failed!");
    }
  };

  const updateIncomingConnections = async (activeUser, requester, value) => {
      try {
          const userRef = await getIncomingConnectionRefByEmail(requester);
          if (userRef) {
              const docRef = doc(db, "incomingConnections", userRef.id);
              
              // Update only the specified field in the signal object
              const newObject = { email: activeUser, type: value.type, timestamp: value.timestamp };

              await updateDoc(docRef, {
                  connections: arrayUnion(newObject)
              });
              

              //alert(`${field} updated successfully!`);
          }
      } catch (error) {
          console.error("Error updating signal:", error);
          alert("Update failed!");
      }
  };

  const startCall = useCallback(async (contact) => {
    //console.log(contact)
    const peerConnection = createPeerConnection(contact.email);

    try {
        //const offer = await peerConnection.createOffer();
        //await peerConnection.setLocalDescription(offer);
        //setOfferSDP(JSON.stringify(peerConnection.localDescription));
        //console.log("sending offer")
        //console.log(peerConnection)
        console.log(peers, currentPeers)
        
        //console.log(activeUser)
        //updateSignalField("offer", JSON.stringify(offer))
        
        //updateSignalField(JSON.stringify(peerConnection.localDescription), user.uid, contact)
        
        //await pause(50);
        //updateSignalField("offer", "")
    } catch (error) {
        console.error('Error creating offer:', error);
    }
  }, [user]);

  const onConfirm = async() => {
    console.log('onConfirm');
    currentPeers.current[shareRequester].signalDataChannel.send(JSON.stringify({type: "acceptedShareScreen", contact: user.email}));
    setAddScreenShare(false)
}

const onCancel = () => {
    console.log('onCancel');
    currentPeers.current[shareRequester].signalDataChannel.send(JSON.stringify({type: "declineShareScreen", contact: user.email}));
    setAddScreenShare(false)
}

const onCancelRequest = () => {
    console.log('onCancelRequest');
    setAddScreenShareRequest(false)
    console.log(currentPeers.current[selectedContact.email].signalDataChannel)
    if (currentPeers.current[selectedContact.email].signalDataChannel.readyState === 'open') {
      currentPeers.current[selectedContact.email].signalDataChannel.send(JSON.stringify({type: "cancelShareScreen", contact: user.email}));
    } else {
      activeComponent.current = 'contacts'
    }

}


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ConnectorContext.Provider value={{ user, setUser, peers, setPeers, currentPeers, contacts, setContacts, currentCall, setCurrentCall, calls, setCalls, selectedContact, setSelectedContact, currentSelectedContact, requester, setRequester, input, setInput, chatMessages, setChatMessages, currentChatMessages, timestamp, remoteTimestamp, polite, makingOffer, setCurrentComponent, activeComponent, createPeerConnection, startListeningUser, updateIncomingConnections, startCall, addMediaStream, fullScreenVideoRef, floatingVideoRef, localStream, remoteStream, endCall, endAudioCall, audio, isRinging, setIsRinging, handleDecline, localAudioRef, remoteAudioRef, addVideo, setAddVideo, addVideoRequest, setAddVideoRequest, startScreenShare, stopScreenShare, sharedScreenStream, screenVideoRef, isSharing, connectionOpen, addScreenShareRequest, setAddScreenShareRequest, activeVideoCall, setActiveVideoCall, activeAudioCall, setActiveAudioCall, incomingFile, chunks, incomingProgress, incomingStatus, incomingSpeed, configuration, createPeerConnection, localOffer, handleOffer, localAnswer, handleAnswer, localIceCandidates, handleCandidate, active, setActive, file, setFile, progress, setProgress, status, setStatus, speed, setSpeed, DBWorker, DBWorkerLoad, stopListeningSignal, collator }}>
      <div style={{margin: 0, padding: 0}}>
        {/*<NotificationComponent />*/}

        {addScreenShare && <div className="video-prompt-modal">
            <div className="video-prompt-content">
                <h3>Request to View Shared Screen</h3>
                <p>{shareRequester} has requested to View Shared Screen. Would you like to view screen?</p>
                
                <div className="video-prompt-buttons">
                <button onClick={onConfirm} className="confirm-button">Yes, View</button>
                <button onClick={onCancel} className="cancel-button">No, Do not View</button>
                </div>
            </div>
        </div>}

        {addScreenShareRequest && <div className="video-prompt-modal">
            <div className="video-prompt-content">
                <h3>Requesting to View Shared Screen</h3>
                <p>Requesting {selectedContact.email} to View Shared Screen</p>
                
                <div className="video-prompt-buttons">
                <button onClick={onCancelRequest} className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>}

        {/* Conditionally render based on currentComponent state */}
        {!user && <Login />}
        {user && currentComponent === 'chatsdfd' && <OutboundCallComponent />}
        {user && currentComponent === 'cons' && <ChatWindow />}
        {user && currentComponent === 'conts' && <ChatList />}
        {user && currentComponent === 'contacts' && <Contacts />}
        {user && currentComponent === 'scan' && <QRScanner />}
        {user && currentComponent === 'chats' && <Chats />}
        {user && currentComponent === 'videoCall' && <VideoCall />}
        {user && currentComponent === 'audioCall' && <AudioCall />}
        {user && currentComponent === 'calling' && <RingerComponent />}
        {user && currentComponent === 'callingAudio' && <RingerAudioComponent />}
        {user && currentComponent === 'dialing' && <DailingComponent />}
        {user && currentComponent === 'dialingAudio' && <DailingAudioComponent />}
        {user && currentComponent === 'connecting' && <ConnectingComponent />}
        {user && currentComponent === 'connectingAudio' && <ConnectingAudioComponent />}
        {user && currentComponent === 'screenShare' && <ScreenShare />}
        {user && currentComponent === 'remote' && <RemoteView />}
        {user && currentComponent === 'fileTransfer' && <FileTransfer />}
        {user && currentComponent === 'manual' && <ManualWebRTC />}
      </div>
    </ConnectorContext.Provider>
  );
/*
  return (
    <Provider store={store}>
      <Signaling />
      <div>
        <h1>Connector</h1>
        {/* Display the current page based on the state *//*}
        {renderPage()}
      </div>
    </Provider>
  );*/
};

export default App;
