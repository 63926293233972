// Contacts.js
import React, { useContext, useRef, useCallback, useState, useEffect } from 'react';
import { db } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../utils/authUtils";
import { getIncomingConnectionRefByEmail, getDocRefByEmail } from "../utils/userUtils";
import { setPeersKey, setContacts, setContactsKey, setChatMessagesKey, setSelectedContactKey } from '../utils/globalSlice'; // Adjust the path accordingly
import { setReference, getReference } from '../utils/referenceStore'; // Adjust the path accordingly
import { ConnectorContext } from '../App';
import './Contacts.css'; // CSS for the layout
import Conversation from './Conversation';
import MessageCard from './MessageCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaWhatsapp, FaFacebook, FaPhone, FaPlus, FaPlusSquare } from 'react-icons/fa';


const messages = [
    {
      profileImage: 'https://example.com/profile1.jpg',
      name: 'Poppy Delevingne',
      time: null,
      unreadCount: 0,
      icons: ['bi-pencil', 'bi-link']
    },
    {
      profileImage: 'https://example.com/profile2.jpg',
      name: 'Jourdan Dunn',
      message: 'Hey how’s the outfit coming along?',
      time: '10:21 am',
      unreadCount: 0,
    },
    // Add more messages here...
];

const Contacts = () => {

    const { user, setUser, currentUser, peers, setPeers , chatMessages, calls, setCalls, setAddScreenShareRequest, currentPeers, contacts, setContacts, selectedContact, setSelectedContact, currentSelectedContact, timestamp, remoteTimestamp, setCurrentComponent, activeComponent, startListeningUser, updateIncomingConnections, createPeerConnection, startCall, polite, makingOffer, addMediaStream, activeVideoCall, setActiveVideoCall, activeAudioCall, active, setActive, DBWorker, DBWorkerLoad, stopListeningSignal, collator } = useContext(ConnectorContext);
 
    const [newContact, setNewContact] = useState({ name: "", email: "" });
    //const navigate = useNavigate();

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownVisible(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (
          dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
          setIsDropdownVisible(false);
        }
    };
/*
    const handleClickOutside = (event) => {
        // Check if the click is outside the dropdown and button
        if (!event.target.closest('.bi-plus-lg') && !event.target.closest('.dropdown-content')) {
            setIsDropdownVisible(false);
        }
    };*/

    useEffect(() => {
        // Add event listener for clicks outside
        document.addEventListener('click', handleClickOutside);

        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    const handleAddContact = async () => {
        if (!user) return;

        const updatedContacts = [...contacts, newContact];
        /*await updateDoc(doc(db, "users", user.uid), {
            contacts: updatedContacts
        });*/

        setContacts(updatedContacts);
        DBWorker.postMessage({ storeName: 'contacts', action: "save", value: [newContact]});

        setNewContact({ name: "", email: "" });
    };

    const handleUpdateContact = async (index, updatedContact) => {
        if (!user) return;

        const updatedContacts = contacts.map((contact, i) =>
        i === index ? updatedContact : contact
        );
        await updateDoc(doc(db, "users", user.uid), {
            contacts: updatedContacts
        });

        setContacts(updatedContacts);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewContact({ ...newContact, [name]: value });
    };

    const chat = (contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            //handleAddConnection(contact)
            //console.log(contact, selectedContact)
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            //const peerConnection = createPeerConnection(contact.email);
            startCall(contact);
        }
        activeComponent.current = 'chats'
        setCurrentComponent('chats')
    };
    
    const audioCall = (contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            startCall(contact);
            activeComponent.current = 'connectingAudio'
            setCurrentComponent('connectingAudio')
        } else if (currentPeers.current[contact.email].remoteStream && currentPeers.current[contact.email].remoteStream.id && currentPeers.current[contact.email].localStream && currentPeers.current[contact.email].localStream.id && activeAudioCall) {
            activeComponent.current = 'audioCall'
            setCurrentComponent('audioCall')
        } else {
            console.log(contact, selectedContact)
            activeComponent.current = 'dialingAudio'
            setCurrentComponent('dialingAudio')
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "audioCall", contact: user.email}));
        
        }
    };
    
    const videoCall = async(contact) => {
        setSelectedContact(contact);
        console.log(currentPeers.current[contact.email])
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            startCall(contact);
            activeComponent.current = 'connecting'
            setCurrentComponent('connecting')
        } else if (currentPeers.current[contact.email].remoteStream && currentPeers.current[contact.email].remoteStream.id && currentPeers.current[contact.email].localStream && currentPeers.current[contact.email].localStream.id && activeVideoCall) {
            activeComponent.current = 'videoCall'
            setCurrentComponent('videoCall')
        } else {
            console.log(contact, selectedContact)
            activeComponent.current = 'dialing'
            setCurrentComponent('dialing')
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "videoCall", contact: user.email}));
        
        }
    };

    const shareScreen = async(contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            startCall(contact);
            activeComponent.current = 'shareScreen'
            setAddScreenShareRequest(true)
        } else {
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "screenShareRequest", contact: user.email}));
            setAddScreenShareRequest(true)
            //startScreenShare(contact.email)
        }
    };
    
    const transferFile = async(contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            //handleAddConnection(contact)
            //console.log(contact, selectedContact)
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            //const peerConnection = createPeerConnection(contact.email);
            startCall(contact);
        }
        activeComponent.current = 'fileTransfer'
        setCurrentComponent('fileTransfer')
    };

    const handleLogout = async () => {
        await stopListeningSignal()
        await handleSignOut();
        setUser(null)
        DBWorkerLoad.postMessage({ storeName: 'lastUser', action: "deleteItem", value: "lastUser"});
        //navigate("/"); // Redirect to the login page after sign-out
    };
    
    const handleConnect = async (contact) => {
        console.log("Manual connect", contact)
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        activeComponent.current = 'manual'
        setCurrentComponent('manual')
    };

    const handleEditContacts = async () => {
        if (active == 'contacts') {
            setActive('add Contact');
        } else if (active == 'chats') {
            console.log('select new chat')
            toggleDropdown()
            //setActive('chats');
        }
    };
    
    const handleReload = async () => {
        currentPeers.current = null;
    };
    
    const handleContacts = async () => {
        setActive('contacts');
    };

    const handleChats = async () => {
        setActive('chats');
    };

    const handleCalls = async () => {
        setActive('calls');
    };

    const handleSettings = async () => {
        setActive('settings');
    };
    
    const handleMessagePreview = async (currentUser) => {
        console.log(currentUser);
        console.log(chatMessages[currentUser]);
        console.log(currentUser);
        console.log(currentUser);
        console.log(currentUser);

        return 'New Message'
    };

    /*function cleanUpJSONString(jsonString) {
        try {
            // 1. Replace single quotes with double quotes (for string values)
            jsonString = jsonString.replace(/\'/g, '"');
    
            // 2. Remove trailing commas in objects or arrays
            jsonString = jsonString.replace(/,\s*([\]}])/g, '$1');
            
            // 3. Remove unnecessary newlines, tabs, or extra spaces
            jsonString = jsonString.trim();
    
            // 4. (Optional) Escape problematic characters (like unescaped newlines)
            // Uncomment this if you need to handle escape sequences
            // jsonString = jsonString.replace(/[\n\r\t]/g, "");
    
            return jsonString;
        } catch (e) {
            console.error('Error while cleaning up JSON string:', e);
            return jsonString; // Return the original if there's an error
        }
    }*/

    function getUserFriendlyDate(dateString) {
        const now = new Date();
        const inputDate = new Date(dateString);
    
        // Get the difference in milliseconds
        const diffInMs = now - inputDate;
    
        // Calculate difference in days
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    
        // Check if it's the same day
        const isToday = now.toDateString() === inputDate.toDateString();
    
        // Format options for time and date
        const timeOptions = { hour: '2-digit', minute: '2-digit' };
        const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
        const dayOptions = { weekday: 'long' };
    
        // Return "Now" if the difference is less than 1 minute
        if (diffInMs < 60 * 1000) {
            return "Now";
        }
    
        // Return the time (HH:MM) if it's today
        if (isToday) {
            return inputDate.toLocaleTimeString(undefined, timeOptions);
        }
    
        // Return the day of the week if it's within the past 7 days
        if (diffInDays <= 7) {
            return inputDate.toLocaleDateString(undefined, dayOptions);
        }
    
        // Return the full date if it's older than 7 days
        return inputDate.toLocaleDateString(undefined, dateOptions);
    }
    
    const handleOptionClick = (event, item) => {
        event.preventDefault(); // Prevent the default anchor behavior
        console.log(`Selected: `, item); // Replace this with your function
        chat(item)
        setIsDropdownVisible(false); // Close dropdown after selection
    };

    return (
        <div>
            <div className="header">
                <div className="header-items">
                    <h1 className="app-title">{active.toLocaleUpperCase()}</h1>
                    {active === 'contacts' && <i className="bi bi-plus-lg" onClick={handleEditContacts} ref={buttonRef} style={active === 'add Contact' ? { color: '#fdb271', fontSize: '24px' } : { fontSize: '24px' }}></i>}
                </div>
                <div className="header-items">
                    {/*active !== 'settings' && <i className="bi bi-plus-lg" onClick={handleEditContacts} ref={buttonRef} style={active === 'add Contact' ? { color: '#fdb271', fontSize: '24px' } : { fontSize: '24px' }}></i>*/}
                    {isDropdownVisible && (
                        <div className="dropdown-content" ref={dropdownRef}>
                        {contacts.filter(elem=>undefined === chatMessages[elem.email]).map(item => (
                            <a
                            href="#" // You can keep the href attribute if you want to style it as a link
                            key={item.email}
                            onClick={(event) => handleOptionClick(event, item)}
                            >
                            {item.name}
                            </a>
                        ))}
                        </div>
                    )}
                    <i className="bi bi-person" onClick={handleContacts} style={active === 'contacts' ? { color: '#fdb271', fontSize: '24px' } : { fontSize: '24px' }}></i>
                    <i className="bi bi-chat-right-dots" onClick={handleChats} style={active === 'chats' ? { color: '#fdb271', fontSize: '24px' } : { fontSize: '24px' }}></i>
                    <i className="bi bi-telephone" onClick={handleCalls} style={active === 'calls' ? { color: '#fdb271', fontSize: '24px' } : { fontSize: '24px' }}></i>
                    <i className="bi bi-gear" onClick={handleSettings} style={active === 'settings' ? { color: '#fdb271', fontSize: '24px' } : { fontSize: '24px' }}></i>
                </div>
            </div>
            <div className="main-content">
                {active === 'add Contact' ? (
                        <div>
                            <h4>Enter Contact Details</h4>
                            <input
                            type="text"
                            name="name"
                            placeholder="Contact Name"
                            value={newContact.name}
                            onChange={handleChange}
                            />
                            <input
                            type="email"
                            name="email"
                            placeholder="Contact Email"
                            value={newContact.email}
                            onChange={handleChange}
                            />
                            <button onClick={handleAddContact}>Add Contact</button>
                        </div>
                ) : active === 'chats' ? (
                    Object.entries(chatMessages).sort((a, b) => b[1].slice(-1)[0].time.localeCompare(a[1].slice(-1)[0].time)).map(entry => entry[0]).map((msg, index) => (
                        <MessageCard
                        key={index}
                        profileImage={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`}
                        name={contacts.filter(elem=>elem.email === msg)[0] ? contacts.filter(elem=>elem.email === msg)[0].name : msg}
                        email={msg}
                        message={chatMessages[msg].slice(-1)[0] ? chatMessages[msg].slice(-1)[0].message.blocks[0].text : null}
                        time={chatMessages[msg].slice(-1)[0] ? getUserFriendlyDate(chatMessages[msg].slice(-1)[0].time.split(' - ')[0]) : null}
                        selected={active}
                        icons={[]}
                        unreadCount={msg.unreadCount}
                        isEven={index % 2 === 0} // Alternate based on index
                        />
                    ))
                ) : active === 'calls' ? (
                    calls.map((call, index) => (
                        <MessageCard
                        key={index}
                        profileImage={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`}
                        name={call.from === user.email ? (contacts.filter(elem=>elem.email === call.to)[0] ? contacts.filter(elem=>elem.email === call.to)[0].name : call.to) : (contacts.filter(elem=>elem.email === call.from)[0] ? contacts.filter(elem=>elem.email === call.from)[0].name : call.from)}//{contacts.filter(elem=>elem.email === msg)[0] ? contacts.filter(elem=>elem.email === msg)[0].name : msg}
                        email={call.from === user.email ? call.to : call.from}
                        message={call}
                        time={call.time ? getUserFriendlyDate(call.time.split(' - ')[0]) : null}
                        selected={active}
                        icons={call.call.type === 'audio' ? ['bi-camera-video', 'bi-display', 'bi-folder-symlink'] : ['bi-telephone-outbound', 'bi-display', 'bi-folder-symlink']}
                        unreadCount={null}
                        isEven={index % 2 === 0} // Alternate based on index
                        />
                    ))
                ) : active === 'settings' ? (
                    <div>
                        <MessageCard
                        key={0}
                        profileImage={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`}
                        name={user.displayName}
                        email={user.email}
                        message={null}
                        time={null}
                        selected={active}
                        icons={[]}
                        unreadCount={null}
                        isEven={0 % 2 === 0} // Alternate based on index
                        />
                        <i className="bi bi-box-arrow-left" onClick={handleLogout} style={{ color: '#fdb271', fontSize: '24px', margin: '20px' }}></i>
                        <i className="bi bi-arrow-clockwise" onClick={handleReload} style={{ color: '#fdb271', fontSize: '24px', margin: '20px' }}></i>
                    </div>
                ) : (
                    contacts.sort((a, b) => collator.compare(a.name, b.name)).map((msg, index) => (
                        <MessageCard
                        key={index}
                        profileImage={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`}
                        name={msg.name}
                        email={msg.email}
                        message={msg.message}
                        time={msg.time}
                        selected={active}
                        icons={active === 'contacts' ? ['bi-chat-right', 'bi-telephone-outbound', 'bi-camera-video', 'bi-display', 'bi-folder-symlink'] : active === 'chats' ? [] : ['bi-telephone-outbound', 'bi-camera-video', 'bi-display', 'bi-folder-symlink']}
                        unreadCount={msg.unreadCount}
                        isEven={index % 2 === 0} // Alternate based on index
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default Contacts;
