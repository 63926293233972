// src/WordProcessor.js
import React, { useContext, useState } from 'react';
import {
  Editor,
  Modifier,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  AtomicBlockUtils,
  CompositeDecorator,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import './WordProcessor.css'; // For custom styling
//import { useSelector, useDispatch } from 'react-redux';
import { setReference, getReference, pushToICECandidates } from '../utils/referenceStore'; 
//import { setInput, setContactsKey, setChatMessages, setCurrentUserKey } from '../utils/globalSlice'; // Adjust the path accordingly
import { ConnectorContext } from '../App';


const WordProcessor = () => {
  const { user, peers, setPeers, contacts, connectionOpen, selectedContact, setSelectedContact, input, setInput, chatMessages, setChatMessages, currentChatMessages, setCurrentComponent, startListeningUser, updateIncomingConnections, DBWorker } = useContext(ConnectorContext);

    const linkStrategy = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
          );
        }, callback);
      };
      
      const Link = (props) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return (
          <a href={url} style={{ color: 'blue', textDecoration: 'underline' }}>
            {props.children}
          </a>
        );
      };
      
      const decorator = new CompositeDecorator([
        {
          strategy: linkStrategy,
          component: Link,
        },
      ]);
  
    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));

  const blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
      case 'left':
        return 'text-align-left';
      case 'center':
        return 'text-align-center';
      case 'right':
        return 'text-align-right';
      default:
        return null;
    }
  };

  const customStyleMap = {
    RED: {
      color: 'red',
    },
  };

  // Toggle the custom style:
  const toggleColor = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'RED'));
  };

  const addLink = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newState = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey);
    setEditorState(EditorState.forceSelection(newState, editorState.getSelection()));
  };

  // Prompt user for URL
  const promptForLink = () => {
    const url = window.prompt('Enter a URL');
    const selection = editorState.getSelection();
    if (!url) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
      return;
    }
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithEntity,
      'apply-entity'
    );
    setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
  };

  const addImage = (editorState, imageUrl) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    setEditorState(newState);
  };

  const removeLink = () => {
    const selection = editorState.getSelection();
    setEditorState(RichUtils.toggleLink(editorState, selection, null));
  };

  const [inlineStyles, setInlineStyles] = useState({
    bold: false,
    italic: false,
    underline: false,
  });

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      updateInlineStyles(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (style) => {
    const newState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(newState);
    updateInlineStyles(newState);
  };

  const updateInlineStyles = (state) => {
    const currentStyle = state.getCurrentInlineStyle();
    setInlineStyles({
      bold: currentStyle.has('BOLD'),
      italic: currentStyle.has('ITALIC'),
      underline: currentStyle.has('UNDERLINE'),
    });
  };

  const onEditorStateChange = (state) => {
    setEditorState(state);
    updateInlineStyles(state);
    setInputValue()
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleAlignment = (alignment) => {
    setEditorState(RichUtils.toggleBlockType(editorState, alignment));
  };

  const exportContent = () => {
    const content = editorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(content));
    console.log(rawContent);
    alert('Content exported! Check console for output.');
  };

  const importContent = () => {
    const rawContent = prompt('Paste the JSON content here');
    if (rawContent) {
      const content = convertFromRaw(JSON.parse(rawContent));
      setEditorState(EditorState.createWithContent(content));
    }
  };

  const blockRendererFn = (contentBlock) => {
    if (contentBlock.getType() === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
      };
    }
    return null;
  };
  
  const MediaComponent = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    return <img src={src} alt="editor-image" style={{ maxWidth: '100%' }} />;
  };

  const undo = () => {
    setEditorState(EditorState.undo(editorState));
  };

  const redo = () => {
    setEditorState(EditorState.redo(editorState));
  };

  const setInputValue = () => {
    const content = editorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(content));
    console.log(rawContent, content);

    let prevInput = input
    if (!prevInput[selectedContact.email]) {
        prevInput = ''
    }
    const updatedInput = {
        ...prevInput,
        [selectedContact.email]: rawContent
    }
    console.log(updatedInput)
    
    setInput(updatedInput);
}

// Function to clear the content
const clearEditor = () => {
    setEditorState(EditorState.createEmpty());
  };
  
const sendMessage = () => {
  const id = selectedContact.email
  const content = editorState.getCurrentContent();
  const rawContent = JSON.stringify(convertToRaw(content));
  console.log(rawContent);
  const message = rawContent//input[id]
  console.log([id])
  console.log(message)
  if (message) {
    const dataChannel = peers[id]?.chatDataChannel;
    console.log(id, message, peers, chatMessages, dataChannel)
    if (dataChannel && dataChannel.readyState === 'open') {
      DBWorker.postMessage({ storeName: 'chats', action: "save", value: [{time: `${new Date().toISOString()} - ${user.email}`, message: JSON.parse(message), to: id, from: user.email}]});
      dataChannel.send(JSON.stringify({ type: 'chat', message }));
      const updatedChatMessages = {
          ...currentChatMessages.current,
          [id]: [...(currentChatMessages.current[id] || []), {time: `${new Date().toISOString()} - ${user.email}`, message: JSON.parse(message), to: id, from: user.email}]
      }
      console.log(updatedChatMessages)

      //setReference(chatMessagesKey, updatedChatMessages);
      currentChatMessages.current = updatedChatMessages
      setChatMessages(updatedChatMessages);
      clearEditor()
      setInputValue()
    } else { 
      DBWorker.postMessage({ storeName: 'chats', action: "save", value: [{time: `${new Date().toISOString()} - ${user.email}`, message: JSON.parse(message), to: id, from: user.email, waiting: true}]});
      const updatedChatMessages = {
          ...currentChatMessages.current,
          [id]: [...(currentChatMessages.current[id] || []), {time: `${new Date().toISOString()} - ${user.email}`, message: JSON.parse(message), to: id, from: user.email, waiting: true}]
      }
      console.log(updatedChatMessages)

      //setReference(chatMessagesKey, updatedChatMessages);
      currentChatMessages.current = updatedChatMessages
      setChatMessages(updatedChatMessages);
      clearEditor()
      setInputValue()
    }
  }
};

  return (
    <div style={{margin: 0, padding: 0, width: '100%', maxHeight: '40vh'}}>
      <div className="toolbar">
        <button
          onClick={() => toggleInlineStyle('BOLD')}
          style={{ backgroundColor: inlineStyles.bold ? '#ddd' : '#f1f1f1', fontWeight: 'bold' }}
        >
          B
        </button>
        <button
          onClick={() => toggleInlineStyle('ITALIC')}
          style={{ backgroundColor: inlineStyles.italic ? '#ddd' : '#f1f1f1', fontStyle: 'italic' }}
        >
          I
        </button>
        <button
          onClick={() => toggleInlineStyle('UNDERLINE')}
          style={{ backgroundColor: inlineStyles.underline ? '#ddd' : '#f1f1f1', textDecoration: 'underline' }}
        >
          U
        </button>
        <button onClick={() => toggleInlineStyle('STRIKETHROUGH')}>
          Strikethrough
        </button>
        <button onClick={() => toggleInlineStyle('CODE')}>Code</button>
      </div>
      <div className="toolbar" style={{display: "none"}}>
          <button onClick={() => toggleBlockType('header-one')}>H1</button>
          <button onClick={() => toggleBlockType('header-two')}>H2</button>
          <button onClick={() => toggleBlockType('header-three')}>H3</button>
          <button onClick={() => toggleBlockType('blockquote')}>Blockquote</button>
          <button onClick={() => toggleBlockType('unordered-list-item')}>
            Bullet List
          </button>
      </div>
      <div className="toolbar" style={{display: "none"}}>
          <button onClick={() => toggleBlockType('ordered-list-item')}>
            Numbered List
          </button>
          <button onClick={() => toggleAlignment('code-block')}>Code</button>
          <button onClick={() => toggleAlignment('left')}>Left Align</button>
          <button onClick={() => toggleAlignment('center')}>Center Align</button>
          <button onClick={() => toggleAlignment('right')}>Right Align</button>
      </div>
      <div className="toolbar" style={{display: "none"}}>
          <button onClick={() => promptForLink()}>addLink</button>
          <button onClick={() => removeLink()}>removeLink</button>
      </div>
      <div className="toolbar" style={{display: "none"}}>
          <button onClick={() => addImage('www.google.com')}>addImage</button>
          <button onClick={() => undo()}>Undo</button>
          <button onClick={() => redo()}>Redo</button>
          <button onClick={() => toggleColor()}>Red Text</button>
      </div>
      <div style={{display: 'flex'}}>
        {<span style={{width: '30px', height: '30px', margin: '10px 0 0 10px'}}><i className="bi bi-plus-lg" style={{ fontSize: '24px' }}></i></span>}
        <div className="editor" style={{margin: '5px', minWidth: '60%'}}>
          <Editor
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onChange={onEditorStateChange}
            blockStyleFn={blockStyleFn}
            blockRendererFn={blockRendererFn}
            customStyleMap={customStyleMap}
            placeholder="Start typing..."
          />
        </div>
        {<span onClick={sendMessage} style={{width: '30px', height: '30px', margin: '8px 0 0 5px', padding: '8px 1px 2px 9px', borderRadius: '20px', backgroundColor: '#007bff', color: 'white'}}><i className="bi bi-send" style={{ fontSize: '20px' }}></i></span>}
      </div>
    </div>
  );
};

// onClick={() => setEditorState(EditorState.moveFocusToEnd(editorState))} 
export default WordProcessor;
