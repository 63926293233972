import React, {useContext } from 'react';
import './MessageCard.css'; // External CSS for styling
import { ConnectorContext } from '../App';

const MessageCard = ({ profileImage, name, email, message, icons, time, selected, unreadCount, isEven }) => {
    const { user, peers, calls, setCalls, setAddScreenShareRequest, currentPeers, contacts, setContacts, currentCall, setCurrentCall, selectedContact, setSelectedContact, currentSelectedContact, timestamp, remoteTimestamp, setCurrentComponent, activeComponent, startListeningUser, updateIncomingConnections, createPeerConnection, startCall, polite, makingOffer, addMediaStream, activeVideoCall, setActiveVideoCall, activeAudioCall, DBWorker } = useContext(ConnectorContext);
    
    const chat = (contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            //handleAddConnection(contact)
            //console.log(contact, selectedContact)
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            //const peerConnection = createPeerConnection(contact.email);
            startCall(contact);
        }
        activeComponent.current = 'chats'
        setCurrentComponent('chats')
    };
    
    const audioCall = (contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        const currentTime = new Date().toISOString()
        currentCall.current = {time: `${currentTime} - ${contact.email}`, call: {type: "audio"}, to: contact.email, from: user.email}
        DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});

        setCalls([currentCall.current].concat(calls))
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            startCall(contact);
            activeComponent.current = 'connectingAudio'
            setCurrentComponent('connectingAudio')
        } else if (currentPeers.current[contact.email].remoteStream && currentPeers.current[contact.email].remoteStream.id && currentPeers.current[contact.email].localStream && currentPeers.current[contact.email].localStream.id && activeAudioCall) {
            activeComponent.current = 'audioCall'
            setCurrentComponent('audioCall')
        } else {
            console.log(contact, selectedContact)
            activeComponent.current = 'dialingAudio'
            setCurrentComponent('dialingAudio')
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "audioCall", contact: user.email}));
        
        }
    };
    
    const videoCall = async(contact) => {
        setSelectedContact(contact);
        console.log(currentPeers.current[contact.email])
        currentSelectedContact.current = contact;
        const currentTime = new Date().toISOString()
        currentCall.current = {time: `${currentTime} - ${contact.email}`, call: {type: "video"}, to: contact.email, from: user.email}
        DBWorker.postMessage({ storeName: 'calls', action: "save", value: [currentCall.current]});
        setCalls([currentCall.current].concat(calls))
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            startCall(contact);
            activeComponent.current = 'connecting'
            setCurrentComponent('connecting')
        } else if (currentPeers.current[contact.email].remoteStream && currentPeers.current[contact.email].remoteStream.id && currentPeers.current[contact.email].localStream && currentPeers.current[contact.email].localStream.id && activeVideoCall) {
            activeComponent.current = 'videoCall'
            setCurrentComponent('videoCall')
        } else {
            console.log(contact, selectedContact)
            activeComponent.current = 'dialing'
            setCurrentComponent('dialing')
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "videoCall", contact: user.email}));
        
        }
    };

    const shareScreen = async(contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            startCall(contact);
            activeComponent.current = 'shareScreen'
            setAddScreenShareRequest(true)
        } else {
            currentPeers.current[contact.email].signalDataChannel.send(JSON.stringify({type: "screenShareRequest", contact: user.email}));
            setAddScreenShareRequest(true)
            //startScreenShare(contact.email)
        }
    };
    
    const transferFile = async(contact) => {
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        if (!currentPeers.current[contact.email] && !remoteTimestamp.current[contact.email]) {
            if (!timestamp.current[contact.email]) {
                timestamp.current[contact.email] = Date.now();
            }
            polite.current[contact.email] = false
            makingOffer.current[contact.email] = false
            //handleAddConnection(contact)
            //console.log(contact, selectedContact)
            updateIncomingConnections(user.email, contact.email, {type: "chats", timestamp: timestamp.current[contact.email]})
            startListeningUser(contact.email, user);
            //const peerConnection = createPeerConnection(contact.email);
            startCall(contact);
        }
        activeComponent.current = 'fileTransfer'
        setCurrentComponent('fileTransfer')
    };
    
    const handleConnect = async (contact) => {
        console.log("Manual connect", contact)
        setSelectedContact(contact);
        currentSelectedContact.current = contact;
        activeComponent.current = 'manual'
        setCurrentComponent('manual')
    };
    
    const handleSelected = async (selected, email, element, message) => {
        if (selected === 'chats') {
            chat(email)
            return
        }
        if (element.className === 'bi-chat-right') {
            chat(email)
        } else if (element.className === 'bi-link') {
            handleConnect(email)
        } else if (element.className === 'bi-folder-symlink') {
            transferFile(email)
        } else if (element.className === 'bi-telephone-outbound') {
            audioCall(email)
        } else if (element.className === 'bi-camera-video') {
            videoCall(email)
        } else if (element.className === 'bi-display') {
            shareScreen(email)
        } else if (selected === 'calls') {
            console.log(message)
            if (message.call.type === 'audio') {
                audioCall(email)
            } else {
                videoCall(email)
            }
        } else if (selected === 'contacts') {
            handleConnect(email)
        }
    };

    return (
        <div className={`message-card ${isEven ? 'even' : 'odd'}`} onClick={(event) => handleSelected(selected, {name: name, email: email}, event.target, message)}>
            <div className="profile-section">
                <img src={profileImage} alt="image" className="profile-img" />
                {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
            </div>
            <div className="message-details">
                <div className="message-header">
                <span className="name">{name}</span>
                {time && <span className="time">{time}</span>}
                </div>
                {message && undefined !== message.call && "Missed" == message.call.handled && "audio" == message.call.type && <p className="message-preview" style={{ color: "#d82d21"}}>
                    {<i className="bi-telephone-inbound" style={{ color: "#8c8c8c", fontSize: "14px", marginRight: "5px" }}></i>}
                    {"Missed"}
                </p>}
                {message && undefined !== message.call && "Missed" !== message.call.handled && "audio" == message.call.type && message.from === email && <p className="message-preview">
                    {<i className="bi-telephone-inbound" style={{ color: "#8c8c8c", fontSize: "14px", marginRight: "5px" }}></i>}
                    {"Incoming"}
                </p>}
                {message && undefined !== message.call && "Missed" !== message.call.handled && "audio" == message.call.type && message.to === email && <p className="message-preview">
                    {<i className="bi-telephone-outbound" style={{ color: "#8c8c8c", fontSize: "14px", marginRight: "5px" }}></i>}
                    {"Outgoing"}
                </p>}
                {message && undefined !== message.call && "Missed" == message.call.handled && "video" == message.call.type && <p className="message-preview" style={{ color: "#d82d21"}}>
                    {<i className="bi-camera-video" style={{ color: "#8c8c8c", fontSize: "14px", marginRight: "5px" }}></i>}
                    {"Missed"}
                </p>}
                {message && undefined !== message.call && "Missed" !== message.call.handled && "video" == message.call.type && message.from === email && <p className="message-preview">
                    {<i className="bi-camera-video" style={{ color: "#8c8c8c", fontSize: "14px", marginRight: "5px" }}></i>}
                    {"Incoming"}
                </p>}
                {message && undefined !== message.call && "Missed" !== message.call.handled && "video" == message.call.type && message.to === email && <p className="message-preview">
                    {<i className="bi-camera-video" style={{ color: "#8c8c8c", fontSize: "14px", marginRight: "5px" }}></i>}
                    {"Outgoing"}
                </p>}
                {message && undefined == message.call && <p className="message-preview">
                    {message}
                </p>}
                {icons && icons.length > 0 ? (
                    icons.map((icon, index) => (
                        <span key={index} className="message-preview">
                            <i className={icon} style={{ color: '#fdb271', fontSize: '24px', marginRight: '20px' }}></i>
                        </span>
                    ))
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
};

export default MessageCard;
