// AudioCall.js
import React, { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode'

const AudioCall = () => {

  const [scanResult, setScanResult] = useState(null)

  useEffect(() => {
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });
  
    scanner.render(success, error);
  
    function success(result) {
      scanner.clear();
      setScanResult(result);
    }
  
    function error(err) {
      console.warn(err);
    }
  
  },[]);
  
  
  return (
    <div>
      <h1>IndexedDB with Web Worker</h1>
      { scanResult 
      ? <div>Success: <a href={scanResult}>{scanResult}</a></div>
      : <div id='reader'></div>
      }
    </div>
  );
};


export default AudioCall;
