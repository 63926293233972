import React, { useContext, useEffect, useState } from "react";
import { ConnectorContext } from '../App';
import './AudioCall.css';

// Ringer Component
const ConnectingAudioComponent = () => {
  const { user, peers, setActiveAudioCall, currentPeers, startScreenShare, setContacts, selectedContact, setSelectedContact, requester, setRequester, setCurrentComponent, createPeerConnection, fullScreenVideoRef, floatingVideoRef, localStream, remoteStream, activeComponent, endAudioCall, localAudioRef, remoteAudioRef, addVideo, setAddVideo, addVideoRequest, setAddVideoRequest, addMediaStream } = useContext(ConnectorContext);
 
  const handleEndCall = () => {
    activeComponent.current = 'contacts'
    setCurrentComponent('contacts')
    if (currentPeers.current[selectedContact.email].signalDataChannel.readyState === 'open') {
        currentPeers.current[selectedContact.email].signalDataChannel.send(JSON.stringify({type: "cancelCall", contact: user.email}));
    }
  };

  const showContacts = () => {
      //activeComponent.current = 'contacts'
      setCurrentComponent('contacts')
  };
  
  return (
      <div className="audio-call-container">
          <div className="top-controls">
              <button id="hide-call-button" onClick={showContacts}><i className="bi bi-arrows-angle-contract" style={{ fontSize: '16px' }}></i></button>
              <div>
                <h3 style={{margin: 0, padding: 0}}>{selectedContact.name}</h3>
                <h5 style={{margin: 0, padding: 0, textAlign: 'center'}}>Calling . . .</h5>
              </div>
              <button id="add-call-button"><i className="bi bi-person-add" style={{ fontSize: '16px', transform: 'scaleX(-1)' }}></i></button>
          </div>

          <img src={`${process.env.PUBLIC_URL}/assets/contact-placeholder.png`} alt="image" className="profile-img-main" />

          <div className="call-controls">
              <button><i className="bi bi-camera-video" style={{ fontSize: '20px', color: '#d2d2d3' }}></i></button>
              <button><i className="bi bi-display" style={{ fontSize: '20px', color: '#d2d2d3' }}></i></button>
              <button>
                  <i className="bi bi-volume-up" style={{ fontSize: '20px' }}></i>
              </button>
              <button>
                  <i className="bi bi-mic-mute" style={{ fontSize: '20px' }}></i>
              </button>
              <button id="end-call-button" onClick={handleEndCall}><i className="bi bi-telephone" style={{ fontSize: '20px' }}></i></button>
          </div>
      </div>
  );
};

export default ConnectingAudioComponent;
